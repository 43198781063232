import React from 'react'
import dynamic from 'next/dynamic'

import { getSiteWideInitialProps, useClientSideCartAndUser } from '@/services/getSiteWideInitialProps'

import type { NextPage } from 'next'
import type { SiteWideSSRProps } from '@/services/getSiteWideInitialProps'

const Row = dynamic(import('@csc/dls/Row'))
const Col = dynamic(import('@csc/dls/Col'))

const Default = dynamic(import('@/layouts/default'))
const LoginForm = dynamic(import('@/components/Login/LoginForm'))
const SignUpForm = dynamic(import('@/components/Login/SignUpForm'))

const LoginPage: NextPage<SiteWideSSRProps> = ({
  sitewideBanner,
  megaNav,
  footer,
}) => {
  const {
    cart, setCart, user, reloadCustomer,
  } = useClientSideCartAndUser()

  return (
    <Default
      reloadCustomer={reloadCustomer}
      user={user}
      sitewideBanner={sitewideBanner}
      megaNav={megaNav}
      meta={{ description: 'Login Page', title: 'Login' }}
      cart={cart}
      onUpdateCart={setCart}
      pageType="login"
      maybeFooterFromContentful={footer}
    >
      <Row className="mt-12 px-2 lg:px-0" cols={2} data-currency-code="USD" gapX="2xl">
        <Col span={{ md: 1, sm: 2 }}>
          <LoginForm />
        </Col>
        <Col span={{ md: 1, sm: 2 }}>
          <SignUpForm />
        </Col>
      </Row>
    </Default>
  )
}

LoginPage.getInitialProps = getSiteWideInitialProps

export default LoginPage
